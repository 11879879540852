<template>
  <div class="widget-container">
    <article>
      <iframe
        :srcdoc="iframeContent"
        style="width: 100%; height: 300vh; border: none;"
      ></iframe>
    </article>
  </div>
</template>

<script>
export default {

    props: {
        widget: {required: true},
        widgetParams: {required: false}
    },

    data: function(){
        return {
            chunkCss: '',
            appCss: '',
            chunkJs: "",
            appJs: "",
            alertsUrl: '',
            dataLang:'',
            isMountedAppScript: false,
            isMountedVendorScript: false,
            questionnaireUrl: "__url_of_questionnaire_page__",
            displayIntradayConsumption: 0
        }
    },

    created() {

            this.chunkCss = this.widget.widgetUrlPrefix + '/' + this.widget.version + '/chunk-vendors.css';
            this.appCss   = this.widget.widgetUrlPrefix + '/' + this.widget.version + '/app.css';
            this.chunkJs  = this.widget.widgetUrlPrefix + '/' + this.widget.version + '/chunk-vendors.js';
            this.appJs    = this.widget.widgetUrlPrefix + '/' + this.widget.version + '/app.js';
            // alert url
            this.alertsUrl = this.$router.resolve({ name: 'alerts', params: { client: this.$store.state.user.client.name }});
            this.alertsUrl = this.alertsUrl.href;

            // dataLang
            this.dataLang=this.$store.state.lang.lang;

            // intraDay
            if(this.widget.widgetParams.hasOwnProperty('displayIntradayConsumption')) {
                this.displayIntradayConsumption = this.widget.widgetParams.displayIntradayConsumption
            }

    },

    computed: {

        iframeContent() {

            return `
                <html>
                <head>
                    <link href="${this.chunkCss}" rel="stylesheet" />
                    <link href="${this.appCss}" rel="stylesheet" />
                </head>
                <body>
                    <div
                        class="smartcoach-app"
                        ref="smartcoachProWidget"
                        data-authentication-token="${this.widget.token}"
                        data-alerts-url="${this.alertsUrl}"
                        data-lang="${this.dataLang}"
                        data-questionnaire-url="${this.questionnaireUrl}"
                        data-housingNb="${this.widget.housingNb}"
                        data-employeeNb="${this.widget.employeeNb}"
                        data-area="${this.widget.area}"
                        data-info-block="1",
                        data-display-intraday-consumption="${this.displayIntradayConsumption}",
                    ></div>
                    <script type='text/javascript' src="${this.chunkJs}">'</scr${'ipt>'}
                    <script type='text/javascript' src="${this.appJs}">'</scr${'ipt>'}
                </body>
                </html>

            `;
        },
    },

}
</script>
