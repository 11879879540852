export default class Configuration {
    static get EnvConfig() {
        return {
            VUE_APP_URL: 'https://onconnectportaladmin-int.onconnect-coach.3slab.fr/api/v1',
            VUE_APP_URL_BASE: 'https://onconnectportaladmin-int.onconnect-coach.3slab.fr/api',
            VUE_APP_RESOURCES_URL: 'https://onconnectportaladmin-int.onconnect-coach.3slab.fr',
            VUE_APP_DASHBOARD_PRO_WIDGET_URL: 'https://storageonconnectcoach.blob.core.windows.net/smartcoachprodashboard/integration/latest',
            VUE_APP_FONT_URL: 'https://smartsolution-onconnectcoach.azureedge.net/smartcoach/integration/resources/fonts',
            VUE_APP_ALARM_WIDGET_URL: 'https://storageonconnectcoach.blob.core.windows.net/smartcoachalarm/integration/latest',
            VUE_APP_JWT_AUTHENTIFICATION_URL: 'https://int.api.smartcoach.3slab.fr/v1/identities',
            VUE_APP_JWT_AUTHENTIFICATION_API_KEY: 'bKLePv0qcURGILitifhKCBAuczgTD2De',
            VUE_APP_MATOMO_HOST: 'https://tracking.onconnect-coach.3slab.fr/',
            VUE_APP_MATOMO_SITE_ID: '2',
            FOLDER: '$FOLDER'
        }

    }
    static value(key) {
        // If the key does not exist in the EnvConfig object of the class, return null
        if (!this.EnvConfig.hasOwnProperty(key)) {
            console.error(`Configuration: There is no key named "${key}". Please add it in Configuration class.`)
            return
        }

        // Get the value
        const value = this.EnvConfig[key]

        // If the value is null, return
        if (!value) {
            console.error(`Configuration: Value for "${key}" is not defined`)
            return
        }

        if (!value.startsWith('$VUE_APP_')) {
            // value was already replaced, it seems we are in production (containerized).
            return value
        }

        // value was not replaced, it seems we are in development.
        const envName = value.substr(1) // Remove $ and get current value from process.env
        const envValue = process.env[envName]

        if (!envValue) {
            console.error(`Configuration: Environment variable "${envName}" is not defined`)
            return
        }

        return envValue
    }
}