<template>
    <div class="container-fluid footer" :style="myBgColorStyle">
        <div class="footer-links">
            <template v-for="(page, index) in pages">
                
                <router-link :style="myTxtColorStyle" :key="index" :to="{ name:'singlePage', params: { client: clientName, pageId: page.id }}">
                    {{page.title}}
                </router-link>
                
                <span :key="index + 100" :style="myTxtColorStyle"> | </span>
            </template>
        </div>
    </div>
</template>




<script>

export default {

    props: {
        pages: {type: Array}
    },

    computed: {
        myBgColorStyle : function(){
            return {
                backgroundColor: this.$store.state.user.template.footerBgColor
            }
        },

        myTxtColorStyle: function () {
            return {
                color: this.$store.state.user.template.footerTextColor
            }
        },

        clientName: function () {
            return this.$store.state.user.client.name;
        }
    }
}

</script>