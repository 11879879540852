<template>
  <div class="widget-container">
    <article>
      <link :href="chunkCss" rel="stylesheet" />
      <link :href="appCss" rel="stylesheet" />
      <div
        class="smartcoach-app"
        ref="smartcoachWidget"
        :data-authentication-token="widget.token"
        :data-collective-hot-water="widget.collectiveHotWater"
        :data-people="widget.people"
        :data-outside-use="widget.outsideUse"
        :data-alerts-url="alertsUrl"
        :data-lang="this.$store.state.lang.lang"
      ></div>
    </article>
  </div>
</template>

<script>
export default {

    props: {
        widget: {required: true},
        widgetParams: {required: false},
    },

    data: function(){
        return {
            chunkCss: '',
            appCss: '',
            alertsUrl: '',
            isMountedAppScript: false,
            isMountedVendorScript: false
        }
    },

    mounted() {

        var smartcoachWidget = this.$refs.smartcoachWidget;

        for (const property in this.widgetParams) {
            smartcoachWidget.setAttribute('data-' + `${property}`, `${this.widgetParams[property]}`);
        }

        let vendorScript = this.widget.widgetUrlPrefix + '/' + this.widget.version + '/chunk-vendors.js'

        let appScript = this.widget.widgetUrlPrefix + '/' + this.widget.version + '/app.js'


        this.$unloadScript(vendorScript)
        .then(() => {
            this.$loadScript(vendorScript).then(() => {
                this.isMountedVendorScript = true;
            }).catch(() => {
                alert('refresh page !')
            });
        })
        .catch(() => {
            this.$loadScript(vendorScript).then(() => {
                this.isMountedVendorScript = true;
            }).catch(() => {
                alert('refresh page !')
            });
        });

        this.$unloadScript(appScript)
        .then(() => {
            this.$loadScript(appScript).then(() => {
                this.isMountedAppScript = true;
            }).catch(() => {
                alert('refresh page !')
            });
        })
        .catch(() => {
            this.$loadScript(appScript).then(() => {
                this.isMountedAppScript = true;
            }).catch(() => {
                alert('refresh page !')
            });
        });

        this.chunkCss = this.widget.widgetUrlPrefix + '/' + this.widget.version + '/chunk-vendors.css';
        this.appCss = this.widget.widgetUrlPrefix + '/' + this.widget.version + '/app.css';


        const alertsUrl = this.$router.resolve({ name: 'alerts', params: { client: this.$store.state.user.client.name }});
        this.alertsUrl = alertsUrl.href;

    }


}
</script>
